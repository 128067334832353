<template>
	<div class="content-pad">
		<div id="pre-h1">Label<template v-if="labels.length > 1">s</template></div>
		<h1>
			<template v-for="(label, i) in labels" :key="i">
				{{ label }}<em v-if="i < labels.length - 1" class="soft"> + </em>
			</template>
		</h1>
		<p class="soft">Label pages are coming soon.</p>
	</div>
	<!-- <TheArtGrid entityType="label" :id="id" /> -->
</template>

<script>
// import TheArtGrid from '@/components/TheArtGrid'

export default {
	name: 'LabelPage',
	components: {
		// TheArtGrid,
	},
	props: {
		label: {
			type: String,
			required: true,
		},
	},
	data() {
		return {}
	},
	computed: {
		labels() {
			return this.label.split('~').map(label => label.replace(/_/g, ' '))
		},
	},
	methods: {},
}
</script>

<style scoped lang="scss">
#pre-h1 {
	margin-bottom: 0.05rem;
}
</style>
